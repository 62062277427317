import React from "react";
import { Typography, Box, Link } from "@cuda-networks/bds-core";
import { NextPageContext } from "next";

export interface ErrorPageProps {
  statusCode: string;
}

export const getInitialProps = function ({ res, err }: NextPageContext) {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

const ErrorPage = ({ statusCode }: ErrorPageProps) => {
  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      padding={3}
    >
      <Box padding={3}>
        <Typography variant="h2">We&rsquo;re sorry.</Typography>
        <Typography variant="h6">
          {statusCode || "Something went wrong."}
          <br />
          Please{" "}
          <Link target="_blank" href="https://www.barracuda.com/support">
            contact support
          </Link>{" "}
          if this problem persists.
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorPage;
